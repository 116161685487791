import React from 'react';
import { Card, CardHeader, CardTitle, CardImage, CardContent } from './Card';

const FlipCard = ({ className, style, children, onClick }) => (
  <Card
    className={`transform duration-100 hover:-translate-y-1 hover:scale-105 ${className}`}
    style={style}
    onClick={onClick}
  >
    {children}
  </Card>
);

const FlipCardHeader = ({ className, style, children }) => (
  <CardHeader className={className} style={style}>
    {children}
  </CardHeader>
);

const FlipCardTitle = ({ className, style, children }) => (
  <CardTitle className={className} style={style}>
    {children}
  </CardTitle>
);

const FlipCardImage = ({ className, style, src, alt }) => (
  <CardImage className={className} style={style} src={src} alt={alt} />
);

const FlipCardContent = ({ className, style, children }) => {
  return (
    <CardContent className={className} style={style}>
      {children}
      <div className='p-3'>
        <i className={'fixed bottom-0 right-0 py-3 pr-3 text-slate-500'}>Click for More</i>
      </div>
    </CardContent>
  );
};

export { FlipCard, FlipCardHeader, FlipCardTitle, FlipCardImage, FlipCardContent };
