import React from 'react';

const Card = ({ className, style, children, onClick }) => (
  <div
    style={style}
    onClick={onClick}
    className={`my-3 transition ease-in-out shadow relative p-4 rounded-lg shadow-md block ${className}`}
  >
    {children}
  </div>
);

const CardHeader = ({ className, style, children }) => (
  <div style={style} className={`mt-0 my-0 flex flex-row ${className}`}>
    <div className={`mx-3`}>{children}</div>
  </div>
);

const CardTitle = ({ className, style, children }) => (
  <div style={style} className={`mb-3 text-xl text-lg ${className}`}>
    {children}
  </div>
);

const CardImage = ({ className, style, src, alt }) => (
  <div className={`mb-3 w-full ${className}`}>
    <img style={style} className={`mx-auto`} src={src} alt={alt || ''} />
  </div>
);

const CardContent = ({ className, style, children, hasBack }) => {
  return (
    <div style={style} className={`block mb-3 ${className}`}>
      {children}
    </div>
  );
};

export { Card, CardHeader, CardTitle, CardImage, CardContent };
