import React from 'react';

const SectionTitle = ({ className, headingLevel, children, variant }) => {
  const validHeadingLevels = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
  const Heading = validHeadingLevels.includes(headingLevel) ? headingLevel : 'h3';

  // default center aligned
  // allows for left or right aligned variants
  const validVariants = ['left', 'right'];
  const alignment = validVariants.includes(variant) ? variant : 'center';

  return <Heading className={`pb-5 ${`text-${alignment}`} ${className}`}>{children}</Heading>;
};

const Section = ({ children, bgImage, bgPosition, className }) => (
  <section>
    <div
      style={{ backgroundImage: `url(${bgImage})` }}
      className={`py-10 bg-cover ${bgPosition || 'bg-center'} bg-fixed bg-no-repeat ${className}`}
    >
      <div className='mx-auto'>{children}</div>
    </div>
  </section>
);

export { Section, SectionTitle };
