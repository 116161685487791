import React, { useState } from 'react';
import Layout from '../components/layout/Layout';
import {
  FlipCard,
  FlipCardHeader,
  FlipCardTitle,
  FlipCardImage,
  FlipCardContent
} from '../components/FlipCard';
import { Transition } from '@headlessui/react';
import { Helmet } from 'react-helmet';

import gsaItSchedule70png from '../images/gsaItSchedule70.png';
import _8a_stars_III from '../images/8a_stars_III.png';
import { Section, SectionTitle } from '../components/Section';

const ContractInfo = ({ url, contractCode, dunsCode, ueiCode, cageCode, pmName, pmEmail }) => {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet='utf-8' />
        <title>LightFeather - Contract Vehicles</title>
      </Helmet>
      <b>Website:</b> <a href={`${url}`}>{url}</a>
      <br /> <b>Contract #:</b> {`${contractCode}`}
      {dunsCode ? (
        <React.Fragment>
          <br /> <b>DUNS #:</b> {`${dunsCode}`}
        </React.Fragment>
      ) : (
        ''
      )}
      {ueiCode ? (
        <React.Fragment>
          <br /> <b>UEI #:</b> {`${ueiCode}`}
        </React.Fragment>
      ) : (
        ''
      )}
      <br /> <b>CAGE #:</b> {`${cageCode}`}
      <br /> <b>Contract PM:</b> {`${pmName}`}
      <br /> <b>PM Email:</b> <a href={`mailto:${pmEmail}`}>{`${pmEmail}`}</a>
    </React.Fragment>
  );
};

const GsaItSchedule70 = () => {
  const [showFront, setShowFront] = useState(true);
  return (
    <FlipCard
      className='w-auto max-w-400-px mx-auto'
      onClick={() => {
        setShowFront(!showFront);
      }}
    >
      <FlipCardHeader>
        <FlipCardTitle className='text-blue'>GSA IT Schedule 70</FlipCardTitle>
      </FlipCardHeader>
      <FlipCardImage
        className='max-h-127-px'
        src={gsaItSchedule70png}
        alt='logo_gsa_it_schedule_70'
      />
      <FlipCardContent>
        {showFront ? (
          <ContractInfo
            url={'https://www.gsa.gov/schedule70'}
            contractCode={'47QTCA21D000X'}
            dunsCode={'081179410'}
            cageCode={'85RF1'}
            pmName={'Sarah Fahden'}
            pmEmail={'sarah.fahden@lightfeather.io'}
          />
        ) : (
          <React.Fragment>
            This multiple award (IDIQ) schedule is the most widely used acquisition vehicle in
            federal, state, and local governments. IT Schedule 70 offers federal, state and local
            governments innovative solutions to their information technology needs by working
            diligently to streamline the procurement process and maximize results. LightFeather’s
            services are available to state, local, and tribal governments through the Cooperative
            Purchasing Program.
          </React.Fragment>
        )}
      </FlipCardContent>
    </FlipCard>
  );
};

const STARSIII = () => {
  const [showFront, setShowFront] = useState(true);
  return (
    <FlipCard
      className='min-w-200-px max-w-400-px mx-auto'
      onClick={() => {
        setShowFront(!showFront);
        console.log(showFront);
      }}
    >
      <FlipCardHeader>
        <FlipCardTitle className='text-blue'>8(a) STARS III</FlipCardTitle>
      </FlipCardHeader>
      <FlipCardImage className='max-h-127-px mt-15' src={_8a_stars_III} alt='8a_stars_III' />
      <FlipCardContent>
        {showFront ? (
          <ContractInfo
            url={'https://www.gsa.gov/s3'}
            contractCode={'47QTCB22D0401'}
            ueiCode={'DWHMBJN4VZG8'}
            dunsCode={'081179410'}
            cageCode={'85RF1'}
            pmName={'Sheelagh Evans'}
            pmEmail={'sheelagh.evans@lightfeather.io'}
          />
        ) : (
          <React.Fragment>
            The Best-in-Class 8(a) STARS III Govermentwide Acquisition Contract (GWAC) is a small
            business set-aside contract that provides flexible access to customized IT solutions
            from a large, diverse pool of 8(a) industry partners. It expands capabilities for
            emerging technologies, supports both outside of the continental United States (OCONUS)
            and CONUS requirements, features limited protestability up to $10M, and offers expert
            guidance from the GSA team, including free scope review services and market research.{' '}
          </React.Fragment>
        )}
      </FlipCardContent>
    </FlipCard>
  );
};
const ContractVechiclesPage = () => {
  return (
    <React.Fragment>
      <head>
        <title>Contract Vechicles Page</title>
      </head>

      <Layout>
        <Section>
          <SectionTitle role='title' headingLevel='h1' className='text-blue bg-white'>
            Contract Vehicles
          </SectionTitle>

          <div className='container mx-auto pt-10 bg-white'>
            <Transition
              show={true}
              appear={true}
              enter='transition-opacity duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity duration-150'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='grid grid-cols-1 md:grid-cols-2 mt-12 px-25 mx-auto'>
                <GsaItSchedule70 />
                <STARSIII />
              </div>
            </Transition>
          </div>
        </Section>
      </Layout>
    </React.Fragment>
  );
};

export default ContractVechiclesPage;
